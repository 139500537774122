import React from 'react';
import Card from '../components/Card';

import '../styles/pages/patrocinadores.scss';
import '../styles/cards.scss';



const Patrocinadores = () => {
    return (
      <div>
         <h1>Nuestros patrocinadores</h1>
		 <div className="card-container-patro">
         <Card
					title="Caixabank"
					thumbnail={process.env.PUBLIC_URL + "/resources/images/caixa.png"}
					thumbnailDescription="caixa"
					link="https://www.caixabank.es/index_es.html">
          Colaborando con nosotros desde 2015
				</Card>

        <Card
					title="Diputación de León "
					thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
					thumbnailDescription="diputación de León"
					link="https://www.dipuleon.es/">
          Colaborando con nosotros desde 2008
				</Card>

        <Card
					title="Banco Santander "
					thumbnail={process.env.PUBLIC_URL + "/resources/images/santander.jpg"}
					thumbnailDescription="banco santander"
					link="https://www.bancosantander.es/particulares">
          Colaborando con nosotros desde 2019
				</Card>

        <Card
					title="Ayuntamiento de Villablino "
					thumbnail={process.env.PUBLIC_URL + "/resources/images/villablino.png"}
					thumbnailDescription="ayuntamiento de villablino"
					link="https://www.aytovillablino.com/">
          Colaborando con nosotros desde nuestros inicios
				</Card>

				<Card
					title="Ayuntamiento de Cabrillanes "
					thumbnail={process.env.PUBLIC_URL + "/resources/images/cabrillanes.png"}
					thumbnailDescription="ayuntamiento de Cabrillanes"
					link="http://www.aytocabrillanes.es/">
          Colaborando con nosotros desde 2019
				</Card>

				
				<Card
					title="Gerencia de servicios sociales de la Junta de CyL "
					thumbnail={process.env.PUBLIC_URL + "/resources/images/gerencia.jpg"}
					thumbnailDescription="Gerencia de servicios sociales de la Junta de CyL "
					link="https://www.jcyl.es/web/jcyl/Portada/es/Plantilla100DirectorioPortada/1248366924958/0/1284448289528/DirectorioPadre">
          Colaborando con nosotros desde nuestros inicios
				</Card>

				<Card
					title="Fundación CEPA "
					thumbnail={process.env.PUBLIC_URL + "/resources/images/cepa.jpg"}
					thumbnailDescription="Fundación CEPA "
					link="https://fundacioncepa.org/">
          Colaborando con nosotros desde 2019
				</Card>
				<Card
					title="Fundación Alimerka "
					thumbnail={process.env.PUBLIC_URL + "/resources/images/Alimerka.jpg"}
					thumbnailDescription="Fundación Alimerka "
					link="https://www.fundacionalimerka.es">
         			 Colaborando con nosotros desde 2022
				</Card>
				<Card
					title="Fundación Roviralta "
					thumbnail={process.env.PUBLIC_URL + "/resources/images/rovi.png"}
					thumbnailDescription="Fundación Roviralta "
					link="https://roviralta.org/">
         			 Colaborando con nosotros desde 2022
				</Card>



				</div>
         
      </div>
    );
}
 
export default Patrocinadores;
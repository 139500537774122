import  { useEffect, React} from 'react';
 
const Error = () => {
	/* 
		Esta función es necesaria para cambiar el título de la página 
	*/
	useEffect(() => {
		document.title = "AFADLA | Esta página no existe";
	});

	return (
		<p>Página web en actualicación. Algunas funciones están en desarrollo</p>
	);
}
 
export default Error;
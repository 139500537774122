import React from 'react';

class Header extends React.Component {
    render(){
        return (
            <div className="flex-item header">
                <div className="header-img">
                    <img src="/resources/images/header.jpg" alt="Foto de AFADLA"/>
                </div>

                <div className="navbar">
                    <a href={process.env.PUBLIC_URL + "/"}>Principal</a>
                    <a href={process.env.PUBLIC_URL + "/colabora"}>Colabora</a>
                    <a href={process.env.PUBLIC_URL + "/services"}>Servicios</a>
                    <a href={process.env.PUBLIC_URL + "/transparency"}>Transparencia</a>
                    <a href={process.env.PUBLIC_URL + "/patrocinadores"}>Patrocinadores</a>
                    <a href={process.env.PUBLIC_URL + "/contact"}>Contacta</a>
                </div>
            </div>
        );
    }
}

export default Header;
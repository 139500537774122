import  { useEffect, React} from 'react';
 
import '../styles/pages/colabora.scss';


const Colabora = (props) => {
	/* 
		Esta función es necesaria para cambiar el título de la página 
		Dicho título se proporciona como un "prop" en el Router (en App.js)
	*/
	useEffect(() => {
		// Si no existe el prop, usa "AFADLA"
		document.title = props.title || "AFADLA";
	});

	return (
		<div className="wrapper">
			<h1>Colabora con nosotros</h1>
			<section>
				<h1>Tipos de socios</h1>
				<ul className="tipos de socios">
					<li>Socio colaborador (12 euros anuales)</li>

					<li>Socio (60 euros anuales)</li>
					
				</ul>

				<h1>Como hacerse socio de AFADLA</h1>
				<p>Hay diversas maneras de poder hacerse socio</p>
				<ul className="hacerse-socio">
					<li> Ponte en contacto con nosotros y te falicitaremos un formulario en papel</li>
					<li> También puedes hacer donaciones a la siguiente cuenta bancaria → ES87 2100 1596 9902 0002 4875 (Caixabanx) </li>
					<li> También puedes colaborar con nosotros rellenando el siguiente formulario online:<br/>
						<form className="formulario-socios" action="mailto:alzheimerlaciana@hotmail.es" method="post" enctype="text/plain">
							<div>
								Nombre:
								<input type="text" name="Nombre" required></input>
							</div>

							<div>
								D.N.I:
								<input type="text" name="D.N.I" required></input>
							</div>

							<div>
								Teléfono:
								<input type="tel" id="phone" name="telefono" pattern="[0-9]{9}" required></input>
							</div>

							<div>
								Domicilio:
								<input type="text" name="Domicilio" required></input>
							</div>

							<div>
								Población:
								<input type="text" name="Población" required></input> <br/>
							</div>

							<div>
								Provincia:
								<input type="text" name="Provincia" required></input>

								C.P:
								<input type="number" name="C.P" pattern="[0-9]{5}" required></input>
							</div>

							<div>
								Deseo colaborar como soci@ con AFADLA, por lo que ruego remitan anualmente un recibo de
								<input type="number" name="Cantidad-a-donar" required></input>
								euros, a mi cuenta			
								<input type="text" name="CuentaBancaria" required></input> <br/>
							</div>

							<div>
								Villablino a:
								<input type="date" name="fecha" required></input> <br/>
							</div>

							<div>
								<input id="accept-donation" type="checkbox" onClick={toggleButton}></input>
								<label for="accept-donation">Acepto la <a href={process.env.PUBLIC_URL + "/privacy-policy"}>política de privacidad</a> y que esta información se envíe al correo <a href="alzheimerlaciana@hotmail.es">alzheimerlaciana@hotmail.es</a></label>
							</div>

							<button id="submit-donation" type="submit" disabled={true}>Enviar</button>
						</form>
					</li>
				</ul>
			</section>
		</div>
	);
}


function toggleButton() {
	let toggle = document.getElementById("accept-donation");
	let button = document.getElementById("submit-donation");

	button.disabled = !toggle.checked;
}

export default Colabora;
import  { useEffect, React} from 'react';

import SimpleMap from '../components/Map';
import '../styles/pages/contacta.scss';

const Contacta = (props) => {
	/* 
		Esta función es necesaria para cambiar el título de la página 
		Dicho título se proporciona como un "prop" en el Router (en App.js)
	*/
	useEffect(() => {
		// Si no existe el prop, usa "AFADLA"
		document.title = props.title || "AFADLA";
	});

	return (
		<div>
			<h1>Contactanos</h1>

			<section>
				<h3>Atención al público</h3>

				<p className="map-address"> Plazo Manuel Iglesias número 8, 24140, Villaseca de Laciana (León)</p>
			</section>
			<section>
				<h3>Centro de día </h3>
				<p>Plaza Manuel Iglesia Nº8, Villaseca de Laciana (León)</p>
			</section>

			<section>
				<h3>Telemáticamente</h3>
				<ul>
					<li>Email: <a href="mailto:alzheimerlaciana@hotmail.es">alzheimerlaciana@hotmail.es</a></li>
					<li>Número de teléfono: 627922285</li>
					<li>Facebook: <a href="https://www.facebook.com/alzheimer.laciana">https://www.facebook.com/alzheimer.laciana</a> </li>
					<li>Instagram: <a href="https://www.instagram.com/alzheimerlaciana/">@alzheimerlaciana</a> </li>
				</ul>
			</section>
		</div>
	);
}

export default Contacta;

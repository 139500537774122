import { useEffect, React } from 'react';

import Card from '../components/Card';

import '../styles/cards.scss';
import '../styles/pages/home_page.scss';
import Noticias from './Noticias';


const Home = (props) => {
	/* 
		Esta función es necesaria para cambiar el título de la página 
		Dicho título se proporciona como un "prop" en el Router (en App.js)
	*/
	useEffect(() => {
		// Si no existe el prop, usa "AFADLA"
		document.title = props.title || "AFADLA";
	});

	return (
		<div className="flex-column">
			<div className="card-container-home">
				<Card
					title="El Alzheimer"
					thumbnail={process.env.PUBLIC_URL + "/resources/images/alzheimer.jpg"}
					thumbnailDescription="A random cat"
					link="/">
					La enfermedad de Alzheimer considerada como la epidemia del siglo XXI, afecta a más de 25 millones de personas.
				</Card>

				<Card
					title="La Asociación"
					thumbnail={process.env.PUBLIC_URL + "/resources/images/afadla.jpg"}
					thumbnailDescription="A random cat"
					link="/">
					Único recurso especializado en el tratamiento no farmacológico de las enfermedades neurodegenativas en la zona.
				</Card>

				<Card
					title="Programas"
					thumbnail={process.env.PUBLIC_URL + "/resources/images/programa.jpg"}
					thumbnailDescription="A random cat"
					link="/">
					AFADLA destina su esfuerzo en programas y servicios para el enfermo, para la familia y para la sociedad
				</Card>

				<Card
					title="Actividades"
					thumbnail={process.env.PUBLIC_URL + "/resources/images/actividades.png"}
					thumbnailDescription="A random cat"
					link="/">
					AFADLA desarrolla actividades de solidaridad, sensibilización y recaudación de fondos, como el Dia Mundial del Alzheimer
				</Card>
			</div>

			<div className="bottom-container" style={{ marginTop: "50px", marginLeft: "20px" }}>
				<div className="description-and-patreons">
					<section>
						<h1>Descripción</h1>
						<p>La Asociación de Familiares de Enfermos de Alzheimer y otras demencias de Laciana (AFADLA)
							dedica su actividad a la atención integral del enfermo de Alzheimer u otras demencias,
							así como el apoyo a sus familias. A través de la intervención no famacológica (TNF) y
							sanitaria AFADLA se marca como objetivo mejorar la calidad de vida del enfermo y su familia,
							así como promover el voluntariado y la sensibilización a cerca de la enfermedad. Ubicada en un entrono rural,
							AFADLA se muestra como único recurso especializado en 65 km.</p>

						<p>En la actualidad, AFADLA lucha por alcanzar la excelencia en su labor, intentando conseguir fondos para la
							reforma de un local en el que centralizar todos los servicios que ofrece y así mejorar la calidad en la atención
							a los enfermos y sus familias. El objetivo está más cerca gracias a la colaboración de Obra Social Caja España-Duero,
							Obra social La Caixa y la colaboracion de numeros industriales y población general con sus donaciones.</p>

						<p>Si quieres colaborar… ¡puedes! Ponte en contacto con AFADLA.</p>

						<p>¡¡AYUDANOS A AYUDARLOS!!</p>
					</section>
					<hr />
					<section>
						<h1>Patrocinadores</h1>
						<ul className="sponsors">
							<li>Caixabank</li>

							<li>Banco Santander</li>

							<li>Ayuntamiento de Villablino</li>

							<li>Diputación de León</li>

							<li>Ayuntamiento de Cabrillanes</li>

							<li>Gerencia de servicios sociales de la Junta de CyL</li>

							<li>Fundación CEPA</li>
							<li>Fundación Alimerka</li>
							<li>Fundación Roviralta</li>


						</ul>
					</section>
				</div>

				<div style={{ flexGrow: 2, marginRight: "10px" }}>
					<h1>Noticias</h1>
					<hr />
					<Noticias />
				</div>
			</div>
		</div>
	);
}

export default Home;
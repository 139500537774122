import React from 'react';
import HCard from '../components/HCard';

export default function Noticias() {
    return (
        <div className="hcard-container">
            <HCard
                title="EN 2024 DIPUTACIÓN DE LEÓN CONCEDE A AFADLA LA CANTIDAD DE 901,39€ PARA EQUIPAMIENTO"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
                c thumbnailDescription="EN 2024 DIPUTACIÓN DE LEÓN CONCEDE A AFADLA LA CANTIDAD DE 901,39€ PARA EQUIPAMIENTO"
                link="">
                La Diputación de León en la convocatoria “Subvenciones dirigidas a entidades privadas sin ánimo de lucro para contribuir a la adquisición y /o reparación del equipamiento necesario para el desarrollo de sus actividades y/o programas de carácter social, año
                2024” ha otorgado a AFADLA la cantidad de 901,39 €.

                Dicha cuantía está destinada a adquirir parte de equipamiento de cocina, una silla de ruedas y mobiliario exterior para el patio del cual nuestr@s usuari@s disfrutan siempre que el tiempo lo permite.
            </HCard>
            <HCard
                title="Ser mayormente divertido, entrenamos las avd"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/noticiaDipuFEB24.png"}
                thumbnailDescription="SER MAYORMENTE DIVERTIDO, ENTRENAMOS LAS AVD"
                link="">
                La Excma Diputación de León ha subvencionado a AFADLA con 8.295,37 € en la
                convocatoria;Subvenciones a proyectos de acción social y servicios sociales
                realizados por entidades privadas sin ánimo de lucro 2023”, esta cuantía ha sido
                destinada a sufragar parte de los gastos originados por el proyecto "Ser
                mayormente divertido", entrenamos las AVD&; que se ha llevado a cabo en el Centro
                Terapéutico Alzheimer Laciana
            </HCard>

            <HCard
                title="Diputación de León Otorga 2460,27 € a AFADLA para Mejorar el Centro Terapéutico Alzheimer Laciana"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
                thumbnailDescription="Diputación de León Otorga 2460,27 € a AFADLA para Mejorar el Centro Terapéutico Alzheimer Laciana"
                link="">
                La Diputación de León en la convocatoria “Subvenciones dirigidas a entidades privadas sin
                ánimo de lucro para contribuir a la adquisición y/o reparación del equipamiento necesario
                para el desarrollo de sus actividades y/o programas de carácter social, año 2023” ha concedido
                a AFADLA un importe de 2460,27 €. Dicha cuantía ha sido destinada a la adquisición de parte
                de mobiliario necesario en el Centro Terapéutico Alzheimer Laciana con el fin de mejorar la
                estancia tanto de usuarios como de trabajadores.
            </HCard>

            <HCard
                title="Momentos Vividos Contigo nuevo proyecto de AFADLA en colaboración con la CAIXA"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/noticiaCaixa.jpg"}
                thumbnailDescription="Ampliando Horizontes: AFADLA y su Programa 'Momentos VIVIDOS CONTIGO' con el Apoyo de Fundación La Caixa y CaixaBank en 2023"
                link="">
                AFADLA un año más ha recibido la ayuda (este año de 2000 €) de @FundlaCaixa a través de @caixabank,
                gracias a la cual hemos podido llevar a cabo el programa "MOMENTOS VIVIDOS CONTIGO",
                iniciado es este 2023, con el cual se trata de ampliar la ayuda a domicilio clásica añadiendo las actividades lúdicas
                y de ocio a la prestación del servicio para así dar una atención integral a la persona que la solicita.
                En la foto de izquierda a derecha podemos ver a Mercedes Viera, presidenta de AFADLA, Julián del Bustio director de
                la oficina de Caixabank en Villablino y Alipio Busto tesorero de AFADLA.
                #FundaciónLaCaixa
                #CaixaBank
            </HCard>

            <HCard
                title="SER MAYORMENTE DIVERTIDO"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
                thumbnailDescription="SER MAYORMENTE DIVERTIDO"
                link="">
                Una vez más AFADLA recibe la colaboración de la Excelentísima Diputación de
                León a través de la subvención concedida en la convocatoria a proyectos de
                acción social y servicios sociales realizados por entidades privadas sin ánimo
                de lucro, año 2022. Gracias a la cual se ha llevado a cabo el proyecto “Ser
                Mayormente Divertido” en colaboración también con el Club Deportivo ALBAMA.
            </HCard>


            <HCard
                title="Nos equipamos"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
                thumbnailDescription="Nos equipamos"
                link="">
                AFADLA en este año 2022 ha sido beneficiaria, con un importe de 2314,07 €, de la subvención
                dirigida a entidades privadas sin ánimo de lucro para contribuir a la adquisición y/o reparación
                del equipamiento necesario para el desarrollo de sus actividades y/o programas de carácter
                social, Año 2022.
                Gracias a esta subvención hemos podido adquirir un equipo informático completo y equipo
                multifunción para el despacho, dando así la jubilación tan necesaria al equipo con el que
                contábamos desde el 2008 y que se había quedado totalmente obsoleto.
                Además hemos podido adquirir una grúa de bipedestación, una silla de ducha con ruedas, así
                como los asideros para las duchas.					</HCard>


            <HCard
                title="VEN CON NOSOTROS"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
                thumbnailDescription="logo diputación león"
                link="/">
                Durante el año 2021 AFADLA ha desarrollado el Proyecto “ Ven con Nosotros” gracias a la colaboración de la Excelentísima Diputación de León  a través de la subvención concedida en la convocatoria a proyectos de acción social y servicios sociales realizados por entidades privadas sin ánimo de lucro, año 2021 y cuya resolución definitiva ha sido aprobada el 18 de febrero de 2022
            </HCard>

            <HCard
                title="AFADLA da el último empujón a su Centro de día"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/nv.jpg"}
                thumbnailDescription="AFADLA da el último empujón a su Centro de día"
                date="2 Marzo, 2022"
                link="https://www.lacianadigital.com/afadla-da-el-ultimo-empujon-a-su-centro-de-dia/215029/">
                Con las ayudas otorgadas por la Fundación Alimerka y la Fundación Roviralta, el colectivo equipará la cocina y la sala de descanso de un inmueble que en su día acogió las antiguas escuelas de la MSP de Villaseca
            </HCard>

            <HCard
                title="AFADLA busca apoyo institucional para concluir su centro de día en Villaseca"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/centro.jpg"}
                thumbnailDescription=""

                date="24 Septiembre, 2021"
                link="https://www.lacianadigital.com/afadla-busca-apoyo-institucional-para-concluir-su-centro-de-dia-en-villaseca/211470/">
                El colectivo está cursando visitas a las instalaciones para que todos los organismos vean, in situ, las carencias que aún tiene el inmueble para lograr la licencia de apertura

            </HCard>

            <HCard
                title="Estoy contigo"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
                thumbnailDescription="A random cat"
                date="15 Julio, 2021"
                link="/">
                AFADLA una vez vistas todas las dificultades por las que han pasado los usuarios de nuestros servicios así como sus familias (sobre todo cuidadores principales) durante el confinamiento, con la finalidad de abrir y mantener canales de comunicación alternativa a la presencial, si las circunstancias lo requieren, entre familias, usuarios y profesionales de AFADLA , desarrolla desde agosto del 2020 hasta diciembre del 2020 el proyecto “Estoy Contigo” (Formación planificada y adaptada a las necesidades de las personas afectadas por demencia tipo Alzheimer u otras, de las actividades instrumentales de la vida diaria, para la adquisición por parte de las PcD y sus cuidadores principales de las destrezas necesarias para el adecuado manejo de las TICS) subvencionado por la Diputación de León.

            </HCard>

            <HCard
                title="Escucha y siente 2"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
                thumbnailDescription="A random cat"
                date="3 septiembre, 2020"
                link="/">
                Continuidad del proyecto "Escucha y siente 2" subvencionado por la Diputación de León en la convocatoria 2019, y cuya resolución…
            </HCard>


            <HCard
                title="Escucha y siente"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
                thumbnailDescription="A random cat"
                date="25 julio, 2018"
                link="/">
                Da comienzo el proyecto "Escucha y siente" subvencionado por la Diputación de León en la convocatoria de 2018 para proyectos…
            </HCard>


            <HCard
                title="Subvención Diputación de León Proyectos de accion social 2017"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
                thumbnailDescription="A random cat"
                date="12 junio, 2018"
                link="/">
                Un año más y bajo el nombre de Estimulación cognitiva a través de la lectoescritura y las nuevas tecnologias, la…
            </HCard>


            <HCard
                title="Convenio de colaboración con Obra social La Caixa 2017"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/caixa.png"}
                thumbnailDescription="A random cat"
                date="22 junio, 2017"
                link="/">
                Para el Proyecto: "Adaptación de instalaciones mecánicas para centro de día especializado en Alzheimer y otras demencias" presentado en la…
            </HCard>


            <HCard
                title="Estimulación cognitiva a través de la lectoescritura y las nuevas tecnologías"
                thumbnail={process.env.PUBLIC_URL + "/resources/images/logoDipu.png"}
                thumbnailDescription="A random cat"
                date="15 enero, 2017"
                link="/">
                AFADLA desarrolla del 1 de Septiembre al 31 de Diciembre de 2016 el proyecto de estimulación cognitiva a través de…
            </HCard>
        </div>
    );
}
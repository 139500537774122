import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import '../styles/map.scss';

class SimpleMap extends React.Component {
	constructor(props){
		super(props);

		this.zoom = this.props.zoom || 11;

		this.center = {
			lat: this.props.latitude || 0,
			lng: this.props.longitude || 0
		};
	}
	
	render(){
		return (
			<div className="map-parent">
				<Map
					className="map"
					google={this.props.google}
					initialCenter={this.center}
					zoom={this.zoom}
				>

					<Marker position={this.center} />

				</Map>
			</div>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyD2mehUn1398JoQ5Rx8vOm4WRfffV2pFVc'
})(SimpleMap);

import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Colabora from './pages/Colabora';
import Contacta from './pages/Contacta';
import Error from './pages/Error';
import Services from './pages/Services';
import Patrocinadores from './pages/Patrocinadores';

import './styles/cards.scss';
import './styles/flex.scss';
import './styles/main.scss';
import './styles/navbar.scss';
import Transparency from './pages/Transparency';

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path='/'
					title="AFADLA"
					element={<Home />} />

				<Route
					path='/about'
					title="AFADLA | Sobre nosotros"
					element={<About />} />

				<Route
					path='/colabora'
					title="AFADLA | Colabora con nosotros"
					element={<Colabora />} />

				<Route
					path='/contact'
					title="AFADLA | Contacta con nosotros"
					element={<Contacta />} />

				<Route
					path='/services'
					title="AFADLA | Nuestros servicios"
					element={<Services />} />


				<Route
					path='/transparency'
					title="AFADLA | Nuestros servicios"
					element={<Transparency />} />
				<Route
					path='/patrocinadores'
					title="AFADLA | Nuestros servicios"
					element={<Patrocinadores />} />

				<Route element={<Error />} />
			</Routes>
		</BrowserRouter>
	);
}

import  { useEffect, React} from 'react';
 
const About = (props) => {
	/* 
		Esta función es necesaria para cambiar el título de la página 
		Dicho título se proporciona como un "prop" en el Router (en App.js)
	*/
	useEffect(() => {
		// Si no existe el prop, usa "AFADLA"
		document.title = props.title || "AFADLA";
	});

	return (
		<div>
				<h1>About US</h1>
				<p>About US page body content</p>
		</div>
	);
}
 
export default About;
import React from 'react';

import '../styles/cards.scss';
import '../styles/pages/services.scss';
import HCard from '../components/HCard';
 
const Services = () => {
    return (
      <div>
        <ol>
         <h1>Servicios</h1>
         <p>Actualmente nuestros servicios son los siguientes: </p>
         <ul className="services-list">
				<li> Atención y cuidado personal en el domicilio (Ayuda a domicilio)<br/></li>
				
          <ul>
            <li>Duchas a demanda</li>
            <li>Intervención Terapéutica</li>
          </ul>
        <li>Transporte adaptado</li>
				<li> Apoyo psicológico y formación socio-sanitaria a personas cuidadoras</li>
        <li>Promoción y formación al Voluntariado</li>
        <li>Promoción de la autonomía personal y prevención de la dependencia en el entorno:</li>
          <ul>
            <li>Servicio de información y asesoramiento</li>

          </ul>
        <li>Formación de la autonomía personal y prevención de la dependencia individual:</li>
          <ul>
            <li>Servicio de promoción, mantenimiento y recuperación funcional</li>
            <li>Servicio de habilitación psicosocial para personas con enfermedad mental o discapacidad intelectudal</li>
            <li>Servicio de estimulación cognitiva</li>
            <li>Servicio de habilitación y terapia ocupacional</li>
          </ul>
        </ul>

        </ol>
        <h1>Próximamente</h1>
        <li>Atención socio-sanitaria en centros de día (en Villaseca de Laciana)</li> <br/>
        
        <div className="news-container">
          <div className="hcard-container">
            <HCard
                      title="Personal" 
                      thumbnail={process.env.PUBLIC_URL + "/resources/images/persona.jpg"}
                      thumbnailDescription="logo persona"
                      >
                         <p align="left">
                        
                         <b>Todas las intervenciones son llevadas a cabo por profesionales 
                          cualificafos en base al modelo de atención centrada en la persona. </b>   <br/>
                          Contamos con los siguientes profesionales:
                          <li> Trabajadora social</li>
                          <li>Terapeuta ocupacional</li>
                          <li>Auxiliar de enfermería (TCAE)</li>
                          <li>Auxiliar de ayuda a domicilio</li>
                          <li>Chófer transporte adaptado</li>
                          </p>
            </HCard>
          </div>
        </div>
      </div>
    );
}
 
export default Services;
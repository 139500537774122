import React from 'react';
import Card from "./Card";

class HCard extends Card {
    constructor(props){
        super(props);

        this.date = props.date;
    }

    render(){
        return (
            <div className="hcard">
                <div className="flex-row">
                    <div className="hcard-img">
                        <img src={this.thumbnail} alt={this.thumbnailDescription} style={{maxWidth: "70%", maxHeight: "100%"}}/>
                    </div>

                    <div style={{flexGrow: 1}}>
                        <div style={{
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "space-evenly",
                            height: "100%"
                        }}>
                            <div>
                                <a href={this.link}><h3 className="hcard-title" >{this.title}</h3></a>
                                <p className="date">{this.date}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p style={{wordWrap: "break-word", margin: "0 20px", textAlign: "left"}}>{this.props.children}</p>
            </div>
        );
    }
}

export default HCard;
import React, { Component } from 'react';

class Card extends Component {
    constructor(props) {
        super(props);

        this.title = props.title;
        this.thumbnail = props.thumbnail;
        this.thumbnailDescription = props.thumbnailDescription;
        this.link = props.link;
    }
    render() {
        return (
            <div className="card">
                <h1>{this.title}</h1>
                <div className='imgcover'>
                    <img src={this.thumbnail} alt={this.thumbnailDescription} />
                </div>
                <p className="card-text">{this.props.children}</p>

                <a href={this.link}>Leer más</a>
            </div>
        );
    }
}

export default Card;
import React from 'react';
import ReactDOM from "react-dom/client";

import App from './App';
import Header from './components/Header';

export default function Index() {
  return (
    <div className="flex-column">
      <Header />

      <div className="flex-item main-body">
        <App />
      </div>

      <footer>
        Alzheimer Laciana © 2022. Privacy Policy
      </footer>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);